import React from 'react';
import PropTypes from 'prop-types';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import styled from '@styled-components';

import Video from 'Components/Video';

const Author = styled('p')``;
const Container = styled('article')``;
const Description = styled('p')``;
const Image = styled('img')``;
const Logotype = styled('img')``;
const Role = styled('span')``;

const Testimonial = ({
  author,
  className,
  description,
  image = '/images/placeholder-image.jpg',
  link,
  logotype = '/images/logotype-inverted.svg',
  role,
  video,
}) => (
  <section className={className}>
    {video ? (
      <Video
        alt={`${author} video`}
        poster={image}
        source={`/videos/${video}.mp4`}
      />
    ) : (
      <Image alt={author} src={image} />
    )}
    <Container>
      <Description>{description}</Description>
      <OutboundLink href={link} itemProp="url" rel="noopener" target="_blank">
        <Logotype alt={`${author} logotype`} src={logotype} />
      </OutboundLink>
      <Author>
        {author}, <Role>{role}</Role>
      </Author>
    </Container>
  </section>
);

Testimonial.propTypes = {
  author: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  link: PropTypes.string,
  logotype: PropTypes.string,
  role: PropTypes.string,
  video: PropTypes.string,
};

export default styled(Testimonial)`
  display: flex;
  flex-direction: column;
  margin: 0 3.2rem;

  ${Image} {
    margin-bottom: 3.2rem;
    max-height: 34rem;
    max-width: 100%;
  }
  
  ${Video} {
    background: url('${props => props.image}') no-repeat center;
    border: none;
    cursor: pointer;
    margin-bottom: 3.2rem;
    height: 16.4rem;
    max-width: 100%;    
    min-height: 16.4rem;
  }

  ${Container} {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    max-width: 100%;
    min-height: 30rem;
    padding: 0;

    ${Author} {
      display: block;
      font-size: 1.4rem;
      font-weight: bold;

      ${Role} {
        font-weight: 400;
      }
    }

    ${Description} {
      font-size: 1.6rem;
      font-style: italic;
      font-weight: 400;
      line-height: 2.4rem;
    }

    ${Logotype} {
      width: 20.4rem;
    }
  }
  @media screen and (min-width: 600px) {
    margin: 0 auto;
    width: 80%;

    ${Video} {
      min-height: 34rem; 
      max-width: 54.4rem
    }
  
    ${Image} {
      max-width: 54.4rem;
    }
  }

  @media screen and (min-width: 1280px) {
    flex-direction: row;
    margin: 0 11.2rem;

    ${Image} {
      max-height: 34rem;
      max-width: 54.4rem;
    }
    
    ${Video} {
      height: 34rem;
      min-width: 54.4rem;
      width: 54.4rem;    
    }

    ${Container} {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-bottom: 3.2rem;
      padding-left: 8rem;
      width: 60%;

      ${Description} {
        font-size: 1.8rem;
      }
    }
  }
`;
