import React, { useCallback, useState } from 'react';
import { withPrefix } from 'gatsby';
import PropTypes from '@prop-types';
import styled from '@styled-components';

import Player, { Source } from 'Components/Player';
import ResponsiveImage from 'Components/ResponsiveImage';

const Video = ({ alt, className, poster, source, sources = [source] }) => {
  const [isPlaying, setPlaying] = useState(false);
  const toggle = useCallback(() => {
    setPlaying(prevState => !prevState);
  }, [setPlaying]);

  return (
    <>
      <button className={className} onClick={toggle} type="button">
        <ResponsiveImage alt={alt} src={withPrefix(poster)} />
      </button>
      {isPlaying && (
        <Player autoPlay onDismiss={toggle}>
          {sources.map(src => (
            <Source key={src}>{src}</Source>
          ))}
        </Player>
      )}
    </>
  );
};

Video.propTypes = {
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  poster: PropTypes.string.isRequired,
  source: PropTypes.string,
  sources: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default styled(Video)`
  border: 0;
  display: flex;
  overflow: hidden;
  outline: none;
  position: relative;

  &::before {
    background-color: rgba(0, 0, 0, 0.25);
    content: '';
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 10;
  }

  &::after {
    background-image: url(${withPrefix('/images/icons/play.svg')});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    content: '';
    height: 8rem;
    left: 50%;
    margin-left: -4rem;
    margin-top: -4rem;
    position: absolute;
    top: 50%;
    width: 8rem;
    z-index: 15;
  }

  ${ResponsiveImage} {
    background-size: cover;
    height: 100%;
    width: 100%;
  }
`;
