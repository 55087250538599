import React, { useEffect, useRef, useState } from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';

const Wrapper = ({ children, className }) => {
  const element = useRef(null);

  const [height, setHeight] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (element.current.querySelector('[data-visible="true"]')) {
        setHeight(
          element.current.querySelector('[data-visible="true"]').offsetHeight,
        );
      }
    }, 0);
    return () => clearTimeout(timer);
  }, [children]);

  return (
    <div className={className} ref={element} style={{ height }}>
      {children}
    </div>
  );
};

Wrapper.propTypes = {
  children: PropTypes.children,
  className: PropTypes.string,
};

export default styled(Wrapper)`
  overflow: hidden;
  transition: height 250ms ease-out;
`;
