import React from 'react';
import PropTypes from '@prop-types';
import { Link as GatsbyLink, withPrefix } from 'gatsby';
import { withIntl, FormattedMessage } from '@react-intl';
import styled from '@styled-components';

import BulletList, { Title, Item } from 'Components/BulletList';
import Slide from 'Components/Slide';

const Button = styled(GatsbyLink)``;
const Card = styled('article')``;
const Description = styled('p')``;
const Icon = styled('img')``;
const Next = styled('img')``;
const Wrapper = styled('div')``;

const ThirdSection = ({ className, in: visible, intl, ...props }) => (
  <Slide
    className={className}
    color="#003566"
    id="second"
    in={visible}
    inverted
    {...props}
  >
    <FormattedMessage
      capitalize
      component={Description}
      id="VALUE_PROPOSITION2.TITLE"
      to="/demo"
    />
    <Wrapper>
      <Card>
        <Icon src="/images/icons/share.png" alt="share" />
        <BulletList
          title="VALUE_PROPOSITION2.1.TITLE"
          items={[
            'VALUE_PROPOSITION2.1.ITEM1',
            'VALUE_PROPOSITION2.1.ITEM2',
            'VALUE_PROPOSITION2.1.ITEM3',
          ]}
        />
      </Card>
      <Card>
        <Icon src="/images/icons/sales.png" alt="sales" />
        <BulletList
          items={[
            'VALUE_PROPOSITION2.2.ITEM1',
            'VALUE_PROPOSITION2.2.ITEM2',
            'VALUE_PROPOSITION2.2.ITEM3',
          ]}
          title="VALUE_PROPOSITION2.2.TITLE"
        />
      </Card>
      <Card>
        <Icon src="/images/icons/support.png" alt="support" />
        <BulletList
          items={[
            'VALUE_PROPOSITION2.3.ITEM1',
            'VALUE_PROPOSITION2.3.ITEM2',
            'VALUE_PROPOSITION2.3.ITEM3',
          ]}
          title="VALUE_PROPOSITION2.3.TITLE"
        />
      </Card>
      <Card>
        <Icon src="/images/icons/enterprise.png" alt="enterprise" />
        <BulletList
          items={[
            'VALUE_PROPOSITION2.4.ITEM1',
            'VALUE_PROPOSITION2.4.ITEM2',
            'VALUE_PROPOSITION2.4.ITEM3',
          ]}
          title="VALUE_PROPOSITION2.4.TITLE"
        />
      </Card>
    </Wrapper>
    <Button to="/demo">
      <FormattedMessage capitalize id="CTA.LEARN_HOW_WE_CAN_HELP" to="/demo" />
      <Next alt="next" src={withPrefix('/images/next-inverted.svg')} />
    </Button>
  </Slide>
);

ThirdSection.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  in: PropTypes.bool,
  intl: PropTypes.intl,
};

export default styled(withIntl(ThirdSection))`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 20%;
  width: 100%;

  ${Wrapper} {
    display: grid;
    grid-gap: 3rem;
    justify-items: center;
    width: 100%;
    margin: 3rem 0;
  }

  ${Description} {
    color: white;
    font-size: 2.8rem;
    letter-spacing: 0;
    line-height: 1.5;
    margin: 0 0 1.6rem;
    text-align: left;
  }

  ${Card} {
    align-items: center;
    background-color: #ffffff;
    border-radius: 0.6rem;
    box-shadow: 0 64px 64px 0 rgba(0, 15, 25, 0.05);
    padding: 2rem;
    position: relative;

    ${BulletList} {
      ${Title} {
        font-size: 1.6rem;
        min-height: inherit;
      }
      ${Item} {
        font-size: 1.4rem;
      }
    }
  }

  ${Icon} {
    height: 3.6rem;
    left: -1.6rem;
    position: absolute;
    top: -1.6rem;
    width: 3.6rem;
  }

  ${Button} {
    align-items: center;
    background-image: linear-gradient(69deg, #005dff, #0095ff);
    border-radius: 0.6rem;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    font-size: 1.4rem;
    height: 4.8rem;
    line-height: 1.4rem;
    padding: 1.6rem;
    text-align: center;
    text-decoration: none;
    width: 100%;

    ${Next} {
      height: 1.2rem;
      margin-left: 1.2rem;
    }
  }
  @media screen and (min-width: 600px) {
    margin-top: 65%;

    ${Description} {
      font-size: 3.2rem;
      margin: 0 0 1.6rem 0;
      text-align: center;
    }

    ${Wrapper} {
      grid-template: 1fr 1fr / 1fr 1fr;
      justify-items: center;
      grid-gap: 4rem;
      margin: 1.6rem 0 1.6rem 0;
    }

    ${Card} {
      padding: 3.2rem 1.6rem 0 3.2rem;
      width: 85%;

      ${BulletList} {
        ${Title} {
          font-size: 2.4rem;
          min-height: inherit;
        }
        ${Item} {
          font-size: 1.8rem;
        }
      }
    }

    ${Icon} {
      height: 7.2rem;
      left: -3.2rem;
      top: -3.2rem;
      width: 7.2rem;
    }

    ${Button} {
      width: 27rem;
    }
  }

  @media screen and (min-width: 840px) {
    margin-top: 0;
  }
`;
