import React, { forwardRef } from 'react';

export function withFordwardRef(WrappedComponent) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name;

  const ForwardRef = forwardRef((props, ref) => (
    <WrappedComponent {...props} innerRef={ref} />
  ));

  ForwardRef.displayName = `withForwardRef(${displayName})`;

  return ForwardRef;
}

export default withFordwardRef;
