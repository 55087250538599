import React, { Component } from 'react';

import hoistNonReactStatics from 'hoist-non-react-statics';
import PropTypes from 'prop-types';
import _ from 'underscore';

import withForwardRef from './forwardRef';

function capitalize(string) {
  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
}

export function withControlledProp(propName) {
  const PropName = capitalize(propName);

  return WrappedComponent => {
    const displayName = WrappedComponent.displayName || WrappedComponent.name;

    class ControlledProp extends Component {
      isControlled = this.props[propName] !== undefined;

      static displayName = `withControlledProp[${propName}](${displayName})`;

      static propTypes = {
        [`default${PropName}`]: PropTypes.any,
        [propName]: PropTypes.any,
        [`on${PropName}Changed`]: PropTypes.func,
      };

      static defaultProps = {
        // eslint-disable-next-line react/default-props-match-prop-types
        [`on${PropName}Changed`]() {},
      };

      state = {
        [propName]: this.props[propName] || this.props[`default${PropName}`],
      };

      handleChange = (after, callback) => {
        const { [`on${PropName}Changed`]: onChange } = this.props;

        if (this.isControlled) {
          const { [propName]: before } = this.props;

          if (!_.isEqual(after, before) || after instanceof File) {
            onChange(after, before);
            callback(after);
          }
        } else {
          let memory;

          this.setState(
            prevState => {
              const { [propName]: before } = prevState;
              memory = before;

              if (!_.isEqual(after, before) || after instanceof File) {
                onChange(after, before);

                return { [propName]: after };
              }

              return undefined;
            },
            () => {
              if (!_.isEqual(after, memory) && typeof callback === 'function') {
                callback(after);
              }
            },
          );
        }
      };

      render() {
        const { isControlled, props, state } = this;
        const { [propName]: value } = isControlled ? props : state;

        return (
          <WrappedComponent
            {...props}
            {...{ [`handle${PropName}`]: this.handleChange, [propName]: value }}
          />
        );
      }
    }

    hoistNonReactStatics(ControlledProp, WrappedComponent);

    return withForwardRef(ControlledProp);
  };
}

export default withControlledProp;
