import React from 'react';
import { withPrefix } from 'gatsby';
import PropTypes from '@prop-types';
import styled from '@styled-components';

import Slide from 'Components/Slide';

export const Heading = styled('span')``;
const Item = styled('li')``;
const List = styled('ul')``;
const Title = styled('h2')``;

const Value = ({
  children,
  className,
  color,
  heading,
  id,
  in: visible,
  items = [],
  title,
  ...props
}) => (
  <Slide
    color={color}
    className={className}
    id={id}
    in={visible}
    {...{ ...props, inverted: undefined }}
  >
    <header>
      {heading && <Heading>{heading}</Heading>}
      <Title>{title}</Title>
    </header>
    {!!items.length && (
      <List>
        {items.map((item, index) => {
          const key = `${index}`;

          return <Item key={key}>{item}</Item>;
        })}
      </List>
    )}
    {children}
  </Slide>
);

Value.propTypes = {
  children: PropTypes.children,
  className: PropTypes.string,
  color: PropTypes.string,
  heading: PropTypes.string,
  id: PropTypes.string,
  in: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
};

export default styled(Value).attrs(props => ({
  image: props.image,
  inverted: props.inverted || false,
}))`
  color: #000f19;
  margin-top: 60%;
  width: 100%;
    
  &.wrapper {
    background: url('${props => props.image}') center top / 75% no-repeat;
  }

  ${Heading} {
    background-color: rgba(149, 0, 255, 0.2);
    border-radius: 0.6rem;
    color: #9500ff;
    display: inline-block;
    font-size: 1rem;
    height: 2.4rem;
    line-height: 1.8rem;
    margin-bottom: 1.6rem;
    padding: 0.4rem 0.8rem;
  }

  ${Title} {
    font-size: 2.8rem;
    letter-spacing: -0.15rem;
    line-height: 1.14;
  }

  ${List} {
    font-size: 1.6rem;
    line-height: 1.5;
    list-style-type: none;
    margin-top: 2.4rem;
    
    ${Item} {
      background-image: url('${props =>
        withPrefix(
          `/images/icons/${props.inverted ? 'check-inverted' : 'check'}.svg`,
        )}');
      background-position: 3.2rem 0.5rem;
      background-repeat: no-repeat;
      line-height: 2.4rem;
      margin-top: 1.6rem;
      padding-left: 6.4rem;
      opacity: 0.6;
    }
  }
  
  @media screen and (min-width: 600px) {
    margin-top: 65%;
    padding: 0 4%;
  
    ${Heading} {
      font-size: 1.2rem;
    }
  
    ${Title} {
      font-size: 4rem;
      line-height: 4.8rem;
    }
    
    ${List} {
      font-size: 1.4rem;
      line-height: 2rem;
      margin-top: 2.4rem;
      
      ${Item} {
        margin-top: 3.2rem;
      }
    }
  }
  
  @media screen and (min-width: 840px) {
    margin-top: 0;
    width: ${props => (props.image ? '60%' : '100%')};
    
    &.wrapper {
      background-position: 78% 45%;
      background-size: 36%;
      justify-content: flex-start;
      width: 100%;
      
      &:nth-of-type(3n+3) {
        background-position: 20% 45%;
        justify-content: flex-end;
      }
    }
  }
  
  @media screen and (min-width: 1280px) {
    padding: 0;
    
    &.wrapper {
      background: url('${props => props.image}') 85% 50% / 40% no-repeat;
      max-width: 144rem;
    }
    
    ${List} {
      font-size: 1.8rem;
      line-height: 2.4rem;
      margin-top: 5.6rem;
      
      ${Item} {
        background-position-y: center;
        margin-top: 3.2rem;
      }
    }
  }
`;
