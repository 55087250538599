import React, { Component, createRef } from 'react';
import { createPortal } from 'react-dom';
import { withPrefix } from 'gatsby';
import { rgba } from 'polished';
import PropTypes from '@prop-types';
import styled from '@styled-components';

const Button = styled('button')``;
const Image = styled('img')``;
const Veil = styled('div')``;
const Video = styled('video')``;
const Wrapper = styled('div')``;

const Source = ({ children }) => <source src={withPrefix(children)} />;

Source.propTypes = {
  children: PropTypes.string,
};

class Player extends Component {
  video = createRef();

  static propTypes = {
    autoPlay: PropTypes.bool,
    children: PropTypes.children,
    className: PropTypes.string,
    onDismiss: PropTypes.func,
  };

  static defaultProps = {
    autoPlay: false,
    onDismiss() {},
  };

  state = { isPaused: true };

  componentDidMount() {
    const { autoPlay } = this.props;

    this.toggle();

    if (
      autoPlay &&
      window.innerWidth < 600 &&
      this.video.current.requestFullscreen
    ) {
      this.video.current.requestFullscreen();

      document.addEventListener(
        'fullscreenchange',
        this.dismissOnFullscreenChange,
      );
    }
  }

  componentWillUnmount() {
    const { autoPlay } = this.props;
    if (
      autoPlay &&
      window.innerWidth < 600 &&
      this.video.current.requestFullscreen
    ) {
      document.removeEventListener(
        'fullscreenchange',
        this.dismissOnFullscreenChange,
      );
    }
  }

  dismissOnFullscreenChange = () => {
    if (!document.fullscreenElement) {
      this.dismiss();
    }
  };

  dismiss = () => {
    const { onDismiss } = this.props;

    this.video.current.pause();

    onDismiss();
  };

  play = () => {
    this.video.current.play();
  };

  toggle = () => {
    this.setState({ isPaused: this.video.current.paused });
  };

  render() {
    const { autoPlay, children, className } = this.props;
    const { isPaused } = this.state;

    return createPortal(
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
      <article className={className} onClick={this.dismiss}>
        <Button>
          <Image
            alt="close"
            src={withPrefix('/images/icons/close-inverted.svg')}
          />
        </Button>

        <Wrapper onClick={e => e.stopPropagation()}>
          {isPaused && (
            <Veil>
              <Button onClick={this.play}>
                <Image alt="play" src={withPrefix('/images/icons/play.svg')} />
              </Button>
            </Veil>
          )}
          <Video
            autoPlay={autoPlay}
            controls
            onPlay={this.toggle}
            onPause={this.toggle}
            ref={this.video}
          >
            {children}
          </Video>
        </Wrapper>
      </article>,
      document.getElementById('video'),
    );
  }
}

export { Source };

export default styled(Player)`
  align-items: center;
  background-color: ${rgba('#000000', 0.75)};
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 1rem 7rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  ${Button} {
    cursor: pointer;
    height: 5rem;
    right: 1rem;
    top: 1rem;
    width: 5rem;
    position: absolute;
    background: transparent;
    border: 0;
  }

  ${Wrapper},
  ${Video} {
    max-height: 100%;
    max-width: 100%;
  }

  ${Wrapper} {
    align-items: center;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: relative;
  }

  ${Veil} {
    align-items: center;
    background-color: ${rgba('#000000', 0.2)};
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;

    ${Button} {
      background: transparent;
      border: 0;
      height: 100%;
      left: 0;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
`;
