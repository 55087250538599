import React from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';
import { FormattedMessage } from '@react-intl';
import Slide from 'Components/Slide';

const BreakLine = styled('div')``;
const Description = styled('p')``;
const Logotype = styled('img')``;
const LogotypeRow = styled('article')``;
const Title = styled('h2')``;

const Customers = ({ className, in: visible }) => (
  <Slide color="#003566" className={className} id="customers" in={visible}>
    <FormattedMessage capitalize component={Title} id="CUSTOMERS">
      customers
    </FormattedMessage>
    <FormattedMessage
      capitalize
      component={Description}
      id="CUSTOMERS.DESCRIPTION"
    >
      {`some of the world's most coveted brands are helped by Joinedapp to
       monetize their marketing campaigns on social media.`}
    </FormattedMessage>
    <BreakLine />
    <LogotypeRow>
      <Logotype alt="estée lauder" src="/images/customers/el.png" />
      <Logotype alt="accor" src="/images/customers/accor.png" />
      <Logotype alt="telenet" src="/images/customers/telenet.png" />
      <Logotype alt="upthrust" src="/images/customers/upthrust.png" />
      <Logotype alt="hln" src="/images/customers/hln.png" />
    </LogotypeRow>
    <LogotypeRow>
      <Logotype alt="vitagene" src="/images/customers/vitagene.png" />
      <Logotype alt="yogo" src="/images/customers/yogo.png" />
      <Logotype alt="bloom bras" src="/images/customers/bb.png" />
      <Logotype
        alt="christina choi cosmetics"
        src="/images/customers/ccc.png"
      />
      <Logotype
        alt="bombom do brasil"
        src="/images/customers/bombom-do-brasil.png"
      />
    </LogotypeRow>
    <LogotypeRow>
      <Logotype alt="sugar babies" src="/images/customers/sugar-babies.png" />
      <Logotype
        alt="california babies"
        src="/images/customers/california-babies.png"
      />
      <Logotype alt="ctc" src="/images/customers/ctc.png" />
      <Logotype alt="punchd" src="/images/customers/punchd.png" />
      <Logotype alt="small world" src="/images/customers/sw.png" />
    </LogotypeRow>
  </Slide>
);

Customers.propTypes = {
  className: PropTypes.string,
  in: PropTypes.bool,
};

export default styled(Customers)`
  color: #ffffff;
  margin-top: 80%;
  padding-bottom: 3.2rem;
  position: relative;

  &.wrapper {
    background: url('/images/backgrounds/customers.svg') center top / 90%
      no-repeat;
  }

  ${Title} {
    font-size: 2.8rem;
    font-weight: 500;
    letter-spacing: -1px;
    margin: 5rem 0 3.2rem;
    text-align: center;
  }

  ${Description} {
    font-size: 1.6rem;
    line-height: 1.5;
    margin: 0 auto;
    max-width: 56rem;
    opacity: 0.6;
    text-align: center;
  }

  ${BreakLine} {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 50%,
      rgba(255, 255, 255, 0) 99.99%,
      rgba(255, 255, 255, 0) 100%
    );
    height: 2px;
    margin: 7.2rem;
    opacity: 0.3;
  }

  ${LogotypeRow} {
    text-align: center;
  }
  @media screen and (min-width: 600px) {
    margin-top: 0%;

    &.wrapper {
      background-size: 50%;
      min-height: auto;
    }

    ${Title} {
      font-size: 4rem;
    }

    ${Description} {
      font-size: 1.8rem;
    }
  }

  @media screen and (min-width: 840px) {
    margin-top: 40%;
    &.wrapper {
      background-size: 40%;
    }
  }
`;
