import React, { PureComponent, createRef } from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';
import { memoize } from 'underscore';

import About from 'Containers/About';
import Customers from 'Containers/Customers';
import FourthSection from 'Containers/FourthSection';
import News from 'Containers/News';
import Partners from 'Containers/Partners';
import Quiz from 'Containers/Quiz';
import SecondSection from 'Containers/SecondSection';
import SeventhSection from 'Containers/SeventhSection';
import SixthSection from 'Containers/SixthSection';
import Team from 'Containers/Team/Slide';
import Testimonials from 'Containers/Testimonials';
import ThirdSection from 'Containers/ThirdSection';

// old summary
// import Summary from 'Containers/Summary';
// temp summary
import TempSummary from 'Containers/TempSummary';

class Index extends PureComponent {
  container = createRef();

  static propTypes = {
    className: PropTypes.string,
  };

  state = { color: '#003566', current: 0 };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  getElements = memoize(current =>
    Index.elements.map((Element, index) => {
      const key = `${index}`;

      return <Element data-animation key={key} in={index === current} />;
    }),
  );

  handleScroll = () => {
    const container = this.container.current;
    const elements = Array.from(container.querySelectorAll('[data-animation]'));
    const screen = window.outerHeight || window.innerHeight;

    const current = elements
      .reverse()
      .find(element => element.getBoundingClientRect().top < screen * 0.6);

    this.setState({
      color: current.dataset.color,
      current: elements.length - 1 - elements.indexOf(current),
    });
  };

  // old summary
  // static elements = [Summary, SecondSection, ThirdSection, FourthSection]; //eslint-disable-line

  // temp summary
  static elements = [TempSummary, SecondSection, ThirdSection, FourthSection]; //eslint-disable-line

  render() {
    const { className } = this.props;
    const { color, current } = this.state;

    return (
      <section
        className={className}
        ref={this.container}
        style={{ backgroundColor: color }}
      >
        {this.getElements(current)}
        <Quiz in />
        <SixthSection in />
        <SeventhSection in />
        <Customers in />
        <Testimonials in />
        <Partners in />
        <News in />
        <About in />
        <Team />
      </section>
    );
  }
}

export default styled(Index)`
  align-items: center;
  display: flex;
  flex-direction: column;
  transition: background-color 0.25s ease-out;
  width: 100%;

  ${Customers} {
    background-color: #003566;
    background-position-y: 6.4rem;
  }
`;
