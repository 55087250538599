import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@react-intl';
import styled from '@styled-components';

import Value from 'Components/Value';

import storage from 'Providers/storage';

import Cover from './Cover';
import Survey from './Survey';
import Score from './Score';

const Quiz = ({ className, in: visible }) => {
  const local = storage.get('QUIZ') || {};

  const intl = useIntl();

  const [survey, setSurvey] = useState([]);
  const [isVisible, setVisibility] = useState(false);
  const [isSent, setSent] = useState(false);
  const [score, setScore] = useState({});

  useLayoutEffect(() => {
    if (Object.keys(local).length > 0) {
      setVisibility(true);
    }
  }, []); //eslint-disable-line

  const handleSubmit = useCallback(async values => {
    const { id, description, score: rating, scoreDescription } = await fetch(
      'https://api.us1.joined.app/api/v1.0/quiz_response?name=d2c_evaluation',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(Object.values(values)),
      },
    ).then(res => res.json());
    setScore({ id, description, rating, result: scoreDescription });
    setSent(prevState => !prevState);
  }, []);

  const toggle = useCallback(() => {
    setVisibility(prevState => !prevState);
  }, [setVisibility]);

  useEffect(() => {
    let current = true;

    (async () => {
      const questions = await fetch(
        'https://api.us1.joined.app/api/v1.0/quiz?name=d2c_evaluation',
      ).then(res => res.json());

      if (current) {
        setSurvey(questions);
      }
    })();

    return () => {
      current = false;
    };
  }, []);

  return (
    <Value
      className={className}
      id="quiz"
      color="#F7F9FC"
      in
      heading={intl.formatMessage({
        capitalize: true,
        id: 'SOCIAL_COMMERCE',
      })}
    >
      {isSent && <Score {...score} />}
      {!isSent && isVisible && (
        <Survey
          onSubmit={handleSubmit}
          questions={survey}
          requestClose={toggle}
        />
      )}
      {!isSent && !isVisible && <Cover onStart={toggle} in={visible} />}
    </Value>
  );
};

Quiz.propTypes = {
  className: PropTypes.string,
  in: PropTypes.bool,
};

export default styled(Quiz)`
  align-items: center;
  color: #000f19;
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin-top: 0;

  &.wrapper {
    background-color: #f7f9fc;
    flex-flow: column;
    min-height: 75vh;
    justify-content: center;
  }

  @media screen and (min-width: 1440px) {
    max-width: 144rem;

    &.wrapper {
      width: 100%;
      max-width: 100%;
    }
  }
`;
