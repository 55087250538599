import React from 'react';
import PropTypes from '@prop-types';
import { FormattedMessage, withIntl } from '@react-intl';
import styled from '@styled-components';
import { Link as GatsbyLink } from 'gatsby';

import Slide from 'Components/Slide';
import Video from 'Components/Video';

const Button = styled(GatsbyLink)``;
const Description = styled('p')``;
const Author = styled('p')``;
const Title = styled('h3')``;
const Wrapper = styled('article')``;

const SixthSection = ({ className, in: visible, intl, ...props }) => (
  <Slide
    className={className}
    color="#003566"
    id="sixth"
    in={visible}
    {...props}
  >
    <Wrapper>
      <FormattedMessage capitalize component={Title} id="VIDEO1.TITLE" />
      <FormattedMessage capitalize component={Description} id="VIDEO1.QUOTE" />
      <FormattedMessage capitalize component={Author} id="VIDEO1.AUTHOR" />
      <FormattedMessage
        capitalize
        component={Button}
        id="CTA.REQUEST_A_DEMO"
        to="/demo"
      />
      <Video
        poster="/videos/features-poster.jpg"
        source="/videos/features.mp4"
        alt="accor video"
      />
    </Wrapper>
  </Slide>
);

SixthSection.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  in: PropTypes.bool,
  intl: PropTypes.intl,
};

export default styled(withIntl(SixthSection))`
  align-items: center;
  flex-direction: column;
  width: 100%;

  ${Wrapper} {
    display: grid;
    grid-template-rows: repeat(auto, 4);
    grid-gap: 3.2rem;

    ${Title} {
      color: white;
      font-size: 2.8rem;
      text-align: center;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 1.5;
      margin: 0;
    }

    ${Description} {
      color: white;
      font-size: 1.8rem;
      letter-spacing: 0;
      line-height: 1.5;
      font-style: italic;
      opacity: 0.8;
    }

    ${Author} {
      color: white;
      font-size: 1.6rem;
      opacity: 0.6;
      margin-bottom: 4rem;
    }

    ${Video} {
      background-image: url('/images/placeholder-image.jpg');
      height: 20rem;
      width: 100%;
      grid-row: 2;
      border-radius: 1rem;
    }

    ${Button} {
      background-image: linear-gradient(69deg, #005dff, #0095ff);
      border-radius: 0.6rem;
      color: white;
      cursor: pointer;
      display: block;
      font-size: 1.4rem;
      height: 4.8rem;
      line-height: 1.4rem;
      padding: 1.6rem;
      text-align: center;
      text-decoration: none;
      width: 100%;
    }
  }

  @media screen and (min-width: 600px) {
    ${Wrapper} {
      ${Video} {
        height: 34rem;
      }
    }
  }

  @media screen and (min-width: 600px) {
    ${Description} {
      font-size: 2rem;
    }
    ${Wrapper} {
      ${Video} {
        height: 45rem;
      }
    }
  }

  @media screen and (min-width: 1025px) {
    ${Wrapper} {
      grid-template: auto 1fr / auto;

      ${Title} {
        font-size: 4rem;
        text-align: left;
      }

      ${Description} {
        font-size: 2.4rem;
      }

      ${Video} {
        grid-row: 1 / span 3;
        grid-column: 2;
        height: 34rem;
        width: 54rem;
        margin-left: 1.6rem;
      }

      ${Button} {
        width: 18.5rem;
      }
    }
  }
`;
