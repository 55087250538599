import React from 'react';
import PropTypes from 'prop-types';
import styled from '@styled-components';
import Slide from 'Components/Slide';
import Team from './Team';

const TeamSlide = ({ className }) => {
  return (
    <Slide className={className} in color="#023565" id="team-slide">
      <Team />
    </Slide>
  );
};

TeamSlide.propTypes = {
  className: PropTypes.string,
};

export default styled(TeamSlide)`
  margin-bottom: 4rem !important;

  ${Team} {
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;
