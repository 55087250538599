import React from 'react';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import styled, { css } from '@styled-components';

import Checkbox from './components/Checkbox';
import Radio from './components/Radio';

const ANSWERS = {
  checkbox: Checkbox,
  radio: Radio,
};

const List = styled('div')``;
const Title = styled('h4')``;

const Question = ({
  className,
  in: isIn,
  index,
  options = [],
  question,
  type = 'radio',
}) => {
  const Answer = ANSWERS[type] || Radio;

  return (
    <CSSTransition appear classNames="" duration={1000} in={isIn} timeout={0}>
      {state => (
        <div className={cx(className, state)} data-visible={isIn}>
          <Title>{question}</Title>
          <List>
            {options.map(option => (
              <Answer
                content={option}
                key={option}
                label={option}
                name={index}
              />
            ))}
          </List>
        </div>
      )}
    </CSSTransition>
  );
};

Question.propTypes = {
  className: PropTypes.string,
  in: PropTypes.bool,
  index: PropTypes.number,
  options: PropTypes.arrayOf(PropTypes.string),
  question: PropTypes.string,
  type: PropTypes.string,
};

export default styled(Question)`
  opacity: 0;

  &.entered {
    opacity: 1;
    transition: opacity 1000ms ease-out;
  }

  &.exiting {
    opacity: 0;
  }

  &.exited {
    display: none;
  }

  ${Title} {
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: 1.2rem;
  }

  ${List} {
    ${props =>
      props.options.length > 6
        ? css`
            display: grid;
            grid-column-gap: 0.8rem;
            grid-template: auto / 1fr 1fr;
          `
        : css`
            align-items: flex-start;
            display: flex;
            flex-direction: column;
          `}
  }

  ${Checkbox}, ${Radio} {
    margin-bottom: 0.8rem;
  }

  @media screen and (min-width: 840px) {
  }

  @media screen and (min-width: 1440px) {
    &.wrapper {
      width: 100%;
      max-width: 100%;
    }
  }
`;
