import React from 'react';
import PropTypes from '@prop-types';
import { FormattedMessage, withIntl } from '@react-intl';
import styled from '@styled-components';
import { Link as GatsbyLink } from 'gatsby';

import Slide from 'Components/Slide';
import Video from 'Components/Video';

const Anchor = styled('a')`
  display: block;
  position: relative;
  top: -30vh;
  visibility: hidden;

  @media screen and (max-width: 600px) {
    top: -15vh;
  }
`;
const Button = styled(GatsbyLink)``;
const Wrapper = styled('article')``;
const Description = styled('p')``;
const Title = styled('h3')``;

const SeventhSection = ({ className, in: visible, intl, ...props }) => (
  <Slide
    className={className}
    color="#f7f9fc"
    id="seventh"
    in={visible}
    {...props}
  >
    <Anchor id="joinedapp-video" />
    <Wrapper>
      <Video
        alt="joinedapp video"
        poster="/videos/promo-poster.jpg"
        sources={['/videos/promo.mp4', '/videos/promo.webm']}
      />
      <FormattedMessage capitalize component={Title} id="VIDEO2.TITLE">
        Learn more about Joinedapp
      </FormattedMessage>
      <FormattedMessage capitalize component={Description} id="VIDEO2.QUOTE">
        Quote
      </FormattedMessage>
      <FormattedMessage
        capitalize
        component={Button}
        id="CTA.REQUEST_A_DEMO"
        to="/demo"
      />
    </Wrapper>
  </Slide>
);

SeventhSection.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  in: PropTypes.bool,
  intl: PropTypes.intl,
};

export default styled(withIntl(SeventhSection))`
  align-items: center;
  flex-direction: column;
  width: 100%;

  ${Wrapper} {
    display: grid;
    grid-template-rows: repeat(auto, 4);
    grid-gap: 3.2rem;
  }

  ${Title} {
    font-size: 2.8rem;
    text-align: center;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.5;
    margin: 0;
  }

  ${Description} {
    font-size: 1.6rem;
    letter-spacing: 0;
    line-height: 1.5;
    font-style: italic;
    opacity: 0.8;
  }

  ${Video} {
    background-image: url('/images/placeholder-image.jpg');
    height: 20rem;
    width: 100%;
    grid-row: 2;
    border-radius: 1rem;
  }

  ${Button} {
    background-image: linear-gradient(69deg, #005dff, #0095ff);
    border-radius: 0.6rem;
    color: white;
    cursor: pointer;
    display: block;
    font-size: 1.4rem;
    height: 4.8rem;
    line-height: 1.4rem;
    padding: 1.6rem;
    text-align: center;
    text-decoration: none;
    width: 100%;
  }

  @media screen and (min-width: 600px) {
    ${Wrapper} {
      ${Video} {
        height: 34rem;
      }
    }
  }

  @media screen and (min-width: 600px) {
    ${Wrapper} {
      ${Video} {
        height: 45rem;
      }
    }
  }

  @media screen and (min-width: 1025px) {
    ${Wrapper} {
      grid-template: auto 1fr / auto;

      ${Title} {
        font-size: 4rem;
        text-align: left;
        grid-column: 2;
      }

      ${Description} {
        font-size: 2.4rem;
        grid-column: 2;
      }

      ${Video} {
        grid-row: 1 / span 3;
        grid-column: 1;
        height: 34rem;
        width: 54rem;
        margin-left: 1.6rem;
      }

      ${Button} {
        width: 18.5rem;
        grid-column: 2;
      }
    }
  }
`;
