/* eslint-disable jsx-a11y/aria-role */
import React, { createRef, PureComponent } from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';

import Slide from 'Components/Slide';
import Carousel, { Item } from 'Components/Carousel';
import Testimonial from 'Components/Testimonial';

class Testimonials extends PureComponent {
  container = createRef();

  static propTypes = {
    className: PropTypes.string,
    in: PropTypes.bool,
  };

  state = { items: 1 };

  render() {
    const { className, in: visible } = this.props;
    const { items } = this.state;

    return (
      <Slide
        color="#f7f9fc"
        className={className}
        id="testimonials"
        in={visible}
      >
        <div ref={this.container} style={{ maxWidth: '100%' }}>
          <Carousel dots infinite items={items}>
            <Item>
              <Testimonial
                author="Kevin Quere"
                description="We believe conversational channels are the next user interfaces and a strategic initiative for AccorHotels. Joinedapp's solution offers a superior user experience provided by an end-to-end booking flow all within chat."
                image="/images/customers/testimonials/accor-picture.png"
                link="https://www.accorhotels.com"
                logotype="/images/customers/testimonials/accor.png"
                role="AccorHotels"
                video="accor"
              />
            </Item>
            <Item>
              <Testimonial
                author="Dennis Peeters"
                description="We are proud of our partnership for helping us onboard our customers such as Het Laatste Nieuws and BASE to the next paradigm in conversational commerce. By working with Joinedapp and Ingenico to enable secure and seamless payments inside chatbots, we saw 6X conversion improvement in some of our use cases."
                image="/images/customers/testimonials/upthrust-picture.png"
                link="https://www.upthrust.eu/"
                logotype="/images/customers/testimonials/upthrust.png"
                role="Innovation lead"
                video="upthrust"
              />
            </Item>
            <Item>
              <Testimonial
                author="Christina Choi"
                description="Through our partnership with Joinedapp, we’ve harnessed the true power of Social Media Commerce - we now offer our customers a complete shopping and checkout experience all within chat!"
                image="/images/customers/testimonials/ccc-picture.png"
                link="https://christinachoicosmetics.com/"
                logotype="/images/customers/testimonials/ccc.png"
                role="CEO and Founder"
              />
            </Item>
            <Item>
              <Testimonial
                author="Elyse Kayes"
                description="Our partnership with Joinedapp puts the power of AI to work for us by enabling features which increase our mobile commerce conversion rates. Joinedapp’s AI-powered solution provides us with a unique set of customer-centric features. These features help us increase our mobile commerce conversion rates. "
                image="/images/customers/testimonials/bb-picture.png"
                link="https://www.bloombras.com/"
                logotype="/images/customers/testimonials/bb.png"
                role="CEO and Founder"
              />
            </Item>
          </Carousel>
        </div>
      </Slide>
    );
  }
}

export default styled(Testimonials)`
  max-width: 144rem;
  width: 100%;
  &.wrapper {
    align-content: center;
    background-color: #f7f9fc;
    padding: 3.2rem 0 0;
    max-width: 100%;
  }
`;
