import React, { useEffect, useState } from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';

const ResponsiveImage = ({ alt, src, ...props }) => {
  const [size, setSize] = useState([0, 0]);

  useEffect(() => {
    const img = document.createElement('img');

    img.onload = () => {
      setSize([img.naturalWidth, img.naturalHeight]);
    };

    img.src = src;
  }, [src]);

  const [width, height] = size;

  return (
    <img
      {...props}
      alt={alt}
      height={height}
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
      width={width}
    />
  );
};

ResponsiveImage.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string,
};

export default styled(ResponsiveImage)`
  background-image: ${props => `url(${props.src})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;
