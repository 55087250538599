import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'spooner';
import styled, { withStyles as css } from '@styled-components';
import { compose } from 'underscore';
import uuid from 'uuid/v4';
import { withPrefix } from 'gatsby';

const InputHTML = styled('input')``;
const Label = styled('label')``;

const Radio = ({ className, label, ...props }) => {
  const prevId = useRef(uuid());
  const {
    content,
    id = prevId.current,
    name,
    onBlur = () => {},
    onChange = () => {},
    onFocus = () => {},
    value = [],
    ...fieldProps
  } = useField(props);

  const handleBlur = () => {
    onBlur();
  };

  const handleChange = event => {
    const { checked } = event.target;

    event.stopPropagation();

    onChange(
      checked ? [...value, content] : value.filter(item => item !== content),
    );
  };

  const handleFocus = () => {
    onFocus();
  };

  return (
    <div
      className={className}
      data-checked={value.includes(content)}
      data-name={name}
    >
      <InputHTML
        {...fieldProps}
        checked={value.includes(content)}
        id={id}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        type="checkbox"
      />
      {label && (
        <Label capitalize htmlFor={id}>
          {label}
        </Label>
      )}
    </div>
  );
};

Radio.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
};

export default compose(
  css`
    align-items: center;
    border: 0.1rem solid #ccdfff;
    border-radius: 0.6rem;
    display: inline-flex;
    min-height: 4.8rem;
    padding: 1.6rem 2.4rem 1.6rem 4rem;
    position: relative;
    transition: border-color 250ms ease-out, background 250ms;

    &::before {
      background: rgba(255, 255, 255, 0);
      background-image: url('${() =>
        withPrefix('/images/icons/white-check.svg')}');
      background-size: 0.5rem;
      background-position: center;
      background-repeat: no-repeat;
      background-color: #FFFFFF;
      border: 0.1rem solid #ccdfff;
      border-radius: 0.2rem;
      content: '';
      height: 1.6rem;
      left: 1.6rem;
      position: absolute;
      transition: border 250ms, background 250ms;
      width: 1.6rem;
    }

    &[data-checked='true'] {
      border-color: #005dff;
      background-color: #E5EFFF;

      &::before {
        background-size: 1rem;
        background-color: #005DFF;
        border-color: #005DFF;
      }
    }



    ${InputHTML} {
      cursor: pointer;
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      width: 100%;
    }

    ${Label} {
      font-size: 1.4rem;
      font-weight: 500;
      color: #005dff;
      vertical-align: middle;
      word-break: break-all;
    }
  `,
)(Radio);
