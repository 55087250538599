import React, { PureComponent } from 'react';
import PropTypes from '@prop-types';

import { FormattedMessage } from '@react-intl';
import styled, { css } from '@styled-components';

const Item = styled('li')``;

class Tab extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  onClick = () => {
    const { label, onClick } = this.props;
    onClick(label);
  };

  render() {
    const {
      onClick,
      props: { className, label },
    } = this;

    return (
      <Item className={className} onClick={onClick}>
        <FormattedMessage id={label} />
      </Item>
    );
  }
}

export default styled(Tab)`
  color: #fff;
  cursor: pointer;
  font-size: 2rem;
  list-style: none;
  margin-right: 1.6rem;
  opacity: 0.65;
  position: relative;
  white-space: nowrap;

  ${({ label, activeTab }) =>
    label === activeTab &&
    css`
      opacity: 1;

      &::before {
        background: #fff;
        content: '';
        position: absolute;
        width: 100%;
        z-index: 2;
        top: 3rem;
        height: 0.2rem;
      }
    `}
`;
