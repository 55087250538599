import React from 'react';
import { Animation, Arc, SVG } from 'calvin-svg';
import { Link as GatsbyLink } from 'gatsby';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedNumber } from '@react-intl';
import styled from '@styled-components';

const Link = styled(GatsbyLink)``;

const Description = styled('p')``;
const Message = styled('p')``;
const Rating = styled('div')``;
const Title = styled('h3')``;

const Score = ({ className, description, id, rating = 0 }) => (
  <div className={className}>
    <Title>Discover how strong is your D2C strategy</Title>
    <Rating>
      <Message>
        <FormattedNumber
          maximumFractionDigits={1}
          minimumFractionDigits={0}
          value={rating}
        />
        <FormattedMessage capitalize id="YOUR_SCORE" />
      </Message>
      <SVG height={144} width={144}>
        <Arc
          color="#005DFF"
          endAngle={(360 * rating) / 100}
          height={144}
          startAngle={0}
          thickness={5}
          width={144}
          x={72}
          y={72}
        >
          <Animation
            attribute="d"
            delay={250}
            duration={1000}
            ease="ease-out"
            fill="freeze"
            step={percentage =>
              Arc.d({
                endAngle: ((360 * rating) / 100) * percentage,
                thickness: 5,
                height: 144,
                startAngle: 0,
                width: 144,
              })
            }
          />
          <Animation
            attribute="opacity"
            delay={250}
            duration={50}
            fill="freeze"
            from={0}
            once
            to={1}
          />
        </Arc>
      </SVG>
    </Rating>
    <Description dangerouslySetInnerHTML={{ __html: description }} />
    <FormattedMessage
      capitalize
      component={Link}
      id="CTA.REQUEST_A_SOCIAL_AUDIT"
      to={`/demo?id=${id}`}
    />
  </div>
);

Score.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.string,
  rating: PropTypes.string,
};

export default styled(Score)`
  background: white;
  border-radius: 0.8rem;
  display: flex;
  padding: 2.4rem 1.6rem;
  flex-direction: column;
  position: relative;
  width: 100%;

  ${Title} {
    font-size: 2.8rem;
    font-weight: 500;
    line-height: 3.2rem;
    margin-bottom: 1.6rem;
  }

  ${Rating} {
    align-items: center;
    display: flex;
    font-size: 1.4rem;
    font-weight: 400;
    height: 14.4rem;
    justify-content: center;
    margin-bottom: 1.2rem;
    position: relative;
    width: 100%;

    span {
      display: block;
      text-align: center;

      &:first-of-type {
        color: #005dff;
        font-size: 4rem;
      }

      &:last-of-type {
        color: #000f19;
        opacity: 0.4;
        font-size: 1.2rem;
      }
    }

    svg {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  ${Description} {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 16px;
    margin-top: 3.2rem;
    margin-bottom: 4rem;
    opacity: 0.6;

    p {
      margin-bottom: 0.8rem;
    }
  }

  ${Link} {
    background-image: linear-gradient(69deg, #005dff, #0095ff);
    border-radius: 0.6rem;
    color: white;
    cursor: pointer;
    display: block;
    font-size: 1.4rem;
    height: 4.8rem;
    line-height: 1.4rem;
    padding: 1.6rem;
    text-align: center;
    text-decoration: none;
    width: 100%;
  }

  @media screen and (min-width: 600px) {
    max-width: 58.4rem;
    padding: 4.4rem 4rem;

    ${Title} {
      max-width: 75%;
    }
    ${Link} {
      width: 20rem;
    }
  }
`;
