import React, { useCallback, useRef, useState } from 'react';
import { withPrefix } from 'gatsby';
import { rgba } from 'polished';
import PropTypes from '@prop-types';
import styled from '@styled-components';

import storage from 'Providers/storage';

import Action from './Action';
import Form from './components/Form';
import Question from './Question';
import Wrapper from './Wrapper';
import ProgressBar from './ProgressBar';

const Actions = styled('div')``;
const Button = styled('button')``;
const Close = styled('button').attrs({ type: 'button' })``;
const Description = styled('p')``;
const Image = styled('img')``;
const Title = styled('h3')``;

const Next = styled(Action)``;
const Previous = styled(Button)``;

const Survey = ({
  className,
  onSubmit = () => {},
  questions = [],
  requestClose = () => {},
}) => {
  const defaultValue = useRef(storage.get('QUIZ'));
  let initialPosition = 0;

  if (defaultValue.current && !!Object.keys(defaultValue.current).length) {
    initialPosition = Object.keys(defaultValue.current).length - 1;
  }

  const [current, setCurrent] = useState(initialPosition);

  const handleSubmit = useCallback(
    (...argv) => {
      storage.remove('QUIZ');
      onSubmit(...argv);
    },
    [onSubmit],
  );

  const handleClose = useCallback(() => {
    storage.remove('QUIZ');
    requestClose();
  }, [requestClose]);

  const next = useCallback(() => {
    setCurrent(prevState => Math.min(prevState + 1, questions.length - 1));
  }, [questions.length, setCurrent]);

  const previous = useCallback(() => {
    setCurrent(prevState => Math.max(prevState - 1, 0));
  }, [setCurrent]);

  const save = useCallback(value => {
    storage.set('QUIZ', value);
  }, []);

  const isFirst = current === 0;
  const isLast = current === questions.length - 1;

  return (
    <section className={className}>
      <Form
        defaultValue={defaultValue.current}
        onChange={save}
        onSubmit={handleSubmit}
      >
        <Title>Discover how strong is your D2C strategy</Title>
        <Close onClick={handleClose}>
          <Image alt="close" src={withPrefix('/images/icons/close.svg')} />
        </Close>
        <Wrapper>
          {questions.map(({ id, ...question }, index) => (
            <Question
              key={id}
              in={index === current}
              index={id}
              {...question}
            />
          ))}
        </Wrapper>
        <Actions>
          {!isFirst && (
            <Previous capitalize onClick={previous} translate type="button">
              ACTION.PREVIOUS_QUESTION
            </Previous>
          )}
          <Next
            capitalize
            field={questions[current] && questions[current].id}
            translate
            {...(isLast ? { type: 'submit' } : { onClick: next })}
          >
            {isLast ? 'ACTION.FINISH' : 'ACTION.NEXT_QUESTION'}
          </Next>
        </Actions>
      </Form>
      <ProgressBar numberOfSteps={questions.length} currentStep={current} />
    </section>
  );
};

Survey.propTypes = {
  className: PropTypes.string,
  requestClose: PropTypes.func,
  onSubmit: PropTypes.func,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.string),
      question: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
};

export default styled(Survey)`
  background: white;
  border-radius: 0.8rem;
  display: flex;
  padding: 2.4rem 1.6rem;
  position: relative;
  width: 100%;

  ${Title} {
    font-size: 2.8rem;
    font-weight: 500;
    line-height: 3.2rem;
    margin-bottom: 1.6rem;
  }

  ${Close} {
    border: 0;
    background: none;
    cursor: pointer;
    position: absolute;
    right: 1.6rem;
    top: 1.6rem;
  }

  ${Description} {
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: 1.2rem;
    opacity: 0.6;
  }

  ${Actions} {
    display: flex;
    margin-top: 1.2rem;
  }

  ${Action}, ${Button} {
    border-radius: 0.6rem;
    cursor: pointer;
    display: block;
    font-size: 1.2rem;
    height: 3.6rem;
    line-height: 1.4rem;
    text-align: center;
    text-decoration: none;
    transition: background 250ms ease-out;
    width: 14rem;
  }

  ${Previous} {
    background: white;
    border: 0.1rem solid ${rgba('#005dff', 0.6)};
    color: #0084ff;
    margin-right: auto;
  }

  ${Form} {
    padding-top: 3.2rem;
  }

  ${Next} {
    background: linear-gradient(15.66deg, #005eff 1.33%, #0095ff 100%);
    border: 0.1rem solid transparent;
    color: white;
    margin-left: auto;

    &[disabled] {
      background: #cccfd1;
      border: 0.1rem solid transparent;
    }
  }

  @media screen and (min-width: 600px) {
    max-width: 58.4rem;
    padding: 4.4rem 4rem;

    ${Title} {
      max-width: 75%;
    }

    ${Actions} {
      margin-top: 4rem;

      ${Action}, ${Button} {
        font-size: 1.4rem;
        height: 4.8rem;
        width: 17rem;
      }
    }
  }
`;
