import React, { createRef, PureComponent } from 'react';
import PropTypes from '@prop-types';
import { FormattedMessage } from '@react-intl';
import styled from '@styled-components';

import Carousel, { Item } from 'Components/Carousel';
import Slide from 'Components/Slide';
import Tabs from 'Components/Tabs';

import FakeTabs from './Partners/FakeTabs';

const Card = styled('div')``;
const Logotype = styled('img')``;
const Logotypes = styled('article')``;
const Title = styled('h3')``;
const Wrapper = styled('section')``;

class Partners extends PureComponent {
  container = createRef();

  static propTypes = {
    className: PropTypes.string,
    in: PropTypes.bool,
  };

  state = { index: 0 };

  updateTab = index => {
    this.setState({ index });
  };

  render() {
    const { className, in: visible } = this.props;
    const { index } = this.state;

    return (
      <Slide color="#003566" className={className} id="partners" in={visible}>
        <FormattedMessage capitalize component={Title} id="PARTNERS.TITLE" />
        <Wrapper data-breakpoint="mobile">
          <FakeTabs
            index={index}
            items={[
              { id: 'PARTNERS.1.TITLE' },
              { id: 'PARTNERS.2.TITLE' },
              { id: 'PARTNERS.3.TITLE' },
              { id: 'PARTNERS.4.TITLE' },
            ]}
          />
          <Carousel dots infinite inverted onPageChange={this.updateTab}>
            <Item>
              <Card label="PARTNERS.1.TITLE">
                <Logotypes>
                  <Logotype
                    alt="Ingenico Group"
                    src="/images/partners/ingenico-group-partner.png"
                  />
                  <Logotype
                    alt="Magento Technology"
                    src="/images/partners/magento-technology-partner.png"
                  />
                  <Logotype
                    alt="WIth Watson"
                    src="/images/partners/with-watson.png"
                  />
                  <Logotype
                    alt="StartX"
                    src="/images/partners/start-x-partner.png"
                  />
                  <Logotype
                    alt="Plug and Play"
                    src="/images/partners/plug-and-play-partner.png"
                  />
                </Logotypes>
              </Card>
            </Item>
            <Item>
              <Card label="PARTNERS.2.TITLE">
                <Logotypes>
                  <Logotype
                    alt="facebook"
                    src="/images/partners/facebook.png"
                  />
                  <Logotype
                    alt="instagram"
                    src="/images/partners/instagram.png"
                  />
                  <Logotype
                    alt="messenger"
                    src="/images/partners/messenger.png"
                  />
                  <Logotype alt="line" src="/images/partners/line.png" />
                  <Logotype alt="skype" src="/images/partners/skype.png" />
                </Logotypes>
                <Logotypes>
                  <Logotype
                    alt="telegram"
                    src="/images/partners/telegram.png"
                  />
                  <Logotype alt="slack" src="/images/partners/slack.png" />
                </Logotypes>
              </Card>
            </Item>
            <Item>
              <Card label="PARTNERS.3.TITLE">
                <Logotypes>
                  <Logotype
                    alt="ingenico"
                    src="/images/partners/ingenico.png"
                  />
                  <Logotype alt="square" src="/images/partners/square.png" />
                  <Logotype alt="stripe" src="/images/partners/stripe.png" />
                  <Logotype
                    alt="apple-pay"
                    src="/images/partners/apple-pay.png"
                  />
                  <Logotype alt="paypal" src="/images/partners/paypal.png" />
                  <Logotype
                    alt="braintree"
                    src="/images/partners/braintree.png"
                  />
                  <Logotype alt="openpay" src="/images/partners/openpay.png" />
                </Logotypes>
              </Card>
            </Item>
            <Item>
              <Card label="PARTNERS.4.TITLE">
                <Logotypes>
                  <Logotype alt="Shopify" src="/images/partners/shopify.png" />
                  <Logotype alt="Magento" src="/images/partners/magento.png" />
                  <Logotype
                    alt="Woocommercer"
                    src="/images/partners/woo-commerce.png"
                  />
                  <Logotype alt="Etsy" src="/images/partners/etsy.png" />
                  <Logotype
                    alt="Salesforce"
                    src="/images/partners/salesforce.png"
                  />
                  <Logotype alt="3D Cart" src="/images/partners/3d-cart.png" />
                </Logotypes>
              </Card>
            </Item>
          </Carousel>
        </Wrapper>
        <Wrapper data-breakpoint="desktop">
          <Tabs>
            <Card label="PARTNERS.1.TITLE">
              <Logotypes>
                <Logotype
                  alt="Ingenico Group"
                  src="/images/partners/ingenico-group-partner.png"
                />
                <Logotype
                  alt="Magento Technology"
                  src="/images/partners/magento-technology-partner.png"
                />
                <Logotype
                  alt="WIth Watson"
                  src="/images/partners/with-watson-partner.png"
                />
                <Logotype
                  alt="StartX"
                  src="/images/partners/start-x-partner.png"
                />
                <Logotype
                  alt="Plug and Play"
                  src="/images/partners/plug-and-play-partner.png"
                />
              </Logotypes>
            </Card>
            <Card label="PARTNERS.2.TITLE">
              <Logotypes>
                <Logotype alt="facebook" src="/images/partners/facebook.png" />
                <Logotype
                  alt="instagram"
                  src="/images/partners/instagram.png"
                />
                <Logotype
                  alt="messenger"
                  src="/images/partners/messenger.png"
                />
                <Logotype alt="line" src="/images/partners/line.png" />
                <Logotype alt="skype" src="/images/partners/skype.png" />
              </Logotypes>
              <Logotypes>
                <Logotype alt="telegram" src="/images/partners/telegram.png" />
                <Logotype alt="slack" src="/images/partners/slack.png" />
              </Logotypes>
            </Card>
            <Card label="PARTNERS.3.TITLE">
              <Logotypes>
                <Logotype alt="ingenico" src="/images/partners/ingenico.png" />
                <Logotype alt="square" src="/images/partners/square.png" />
                <Logotype alt="stripe" src="/images/partners/stripe.png" />
                <Logotype
                  alt="apple-pay"
                  src="/images/partners/apple-pay.png"
                />
                <Logotype alt="paypal" src="/images/partners/paypal.png" />
                <Logotype
                  alt="braintree"
                  src="/images/partners/braintree.png"
                />
                <Logotype alt="openpay" src="/images/partners/openpay.png" />
              </Logotypes>
            </Card>
            <Card label="PARTNERS.4.TITLE">
              <Logotypes>
                <Logotype alt="Shopify" src="/images/partners/shopify.png" />
                <Logotype alt="Magento" src="/images/partners/magento.png" />
                <Logotype
                  alt="Woocommercer"
                  src="/images/partners/woo-commerce.png"
                />
                <Logotype alt="Etsy" src="/images/partners/etsy.png" />
                <Logotype
                  alt="Salesforce"
                  src="/images/partners/salesforce.png"
                />
                <Logotype alt="3D Cart" src="/images/partners/3d-cart.png" />
              </Logotypes>
            </Card>
          </Tabs>
        </Wrapper>
      </Slide>
    );
  }
}

export default styled(Partners)`
  color: white;
  padding-top: 6.4rem;
  width: 100%;

  ${Title} {
    margin-bottom: 4rem;
    font-size: 2.8rem;
    font-weight: 400;
  }
  ${Logotypes} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    opacity: 0.8;

    ${Logotype} {
      max-width: 40vw;
    }
  }

  ${Carousel} {
    ul {
      margin: 0;
    }
    ${Item} {
      align-items: center;
      display: flex;
    }
  }

  ${Wrapper} {
    &[data-breakpoint='desktop'] {
      display: none;
    }
  }

  @media screen and (min-width: 600px) {
    ${Wrapper} {
      height: 78rem;
    }

    ${Title} {
      font-size: 4rem;
      margin-left: 7.6rem;
    }

    ${Carousel} button {
      margin: 0;
    }
  }

  @media screen and (min-width: 840px) {
    ${Wrapper} {
      height: 55rem;

      &[data-breakpoint='mobile'] {
        display: none;
      }

      &[data-breakpoint='desktop'] {
        display: block;
      }
    }
  }
`;
