import React from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';
import { FormattedMessage } from '@react-intl';

export const Item = styled('li')``;

export const OrderedList = ({ className, items, ...props }) => (
  <ol className={className} {...props}>
    {items.map(item => (
      <FormattedMessage
        capitalize
        component={Item}
        id={item}
        itemScope
        itemType="http://schema.org/ListItem"
        key={JSON.stringify(item)}
      />
    ))}
  </ol>
);

OrderedList.defaultProps = {
  className: '',
  items: [],
};

OrderedList.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.any),
};

export default styled(OrderedList)`
  counter-reset: counter;
  font-size: 2.4rem;
  list-style: none;
  margin: 0;
  opacity: 0.8;
  padding: 0;

  ${Item} {
    counter-increment: counter;
    font-size: 1.8rem;
    margin-bottom: 3.2rem;
    margin-top: 1.6rem;
    opacity: 0.8;

    &::before {
      content: counter(counter) ' - ';
      font-weight: bold;
      margin-right: 0.5rem;
      opacity: 1;
    }
  }

  @media screen and (min-width: 600px) {
    ${Item} {
      font-size: 2.4rem;
    }
  }
`;
