import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@styled-components';

import Tab from './Tab';

const List = styled('ul')``;
const Container = styled('div')``;
const Wrapper = styled('div')``;

class Tabs extends Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.instanceOf(Array).isRequired,
  };

  state = {
    activeTab: this.props.children[0].props.label,
  };

  onClickTabItem = tab => {
    this.setState({ activeTab: tab });
  };

  render() {
    const {
      onClickTabItem,
      props: { children, className },
      state: { activeTab },
    } = this;

    return (
      <Container className={className}>
        <List>
          {children.map(child => (
            <Tab
              activeTab={activeTab}
              key={child.props.label}
              label={child.props.label}
              onClick={onClickTabItem}
            />
          ))}
        </List>
        <Wrapper>
          {children.map(child => {
            if (child.props.label !== activeTab) return undefined;
            return child.props.children;
          })}
        </Wrapper>
      </Container>
    );
  }
}

export default styled(Tabs)`
  height: 100%;
  position: relative;

  &::before {
    background: #fff;
    top: 4rem;
    content: '';
    height: 0.2rem;
    opacity: 0.2;
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  ${List} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 0;
    overflow-x: scroll;
  }

  ${Wrapper} {
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: calc(100% - 8rem);
  }

  @media screen and (min-width: 900px) {
    &::before {
      width: calc(100% - 16rem);
      left: 8rem;
    }

    ${List} {
      margin: 0 8rem;
      overflow-x: inherit;
    }
  }
`;
