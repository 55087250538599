import React from 'react';
import PropTypes from '@prop-types';
import { Link as GatsbyLink, withPrefix } from 'gatsby';
import { FormattedMessage, withIntl } from '@react-intl';

import styled from '@styled-components';

import BulletList, { Title as BulletTitle, Item } from 'Components/BulletList';
import Slide from 'Components/Slide';

const Button = styled(GatsbyLink)``;
const Image = styled('img')``;
const Next = styled('img')``;
const Title = styled('h3')``;
const ValueProposition = styled('section')``;
const Wrapper = styled('div')``;

const FourthSection = ({ className, in: visible, intl, ...props }) => (
  <Slide
    className={className}
    color="#FFF"
    id="third"
    in={visible}
    heading={intl.formatMessage({
      capitalize: true,
      id: 'SOCIAL_COMMERCE',
    })}
    {...props}
  >
    <FormattedMessage
      capitalize
      component={Title}
      id="VALUE_PROPOSITION3.TITLE"
    >
      Joinedapp Enables Brands & Businesses
    </FormattedMessage>
    <ValueProposition>
      <Wrapper>
        <Image src="/images/conversion-rates.png" alt="conversion-rates" />
        <BulletList
          title="VALUE_PROPOSITION3.1.TITLE"
          items={[
            'VALUE_PROPOSITION3.1.ITEM1',
            'VALUE_PROPOSITION3.1.ITEM2',
            'VALUE_PROPOSITION3.1.ITEM3',
          ]}
        />
      </Wrapper>
      <Wrapper>
        <Image src="/images/brand-awareness.png" alt="brand awareness" />
        <BulletList
          title="VALUE_PROPOSITION3.2.TITLE"
          items={[
            'VALUE_PROPOSITION3.2.ITEM1',
            'VALUE_PROPOSITION3.2.ITEM2',
            'VALUE_PROPOSITION3.2.ITEM3',
          ]}
        />
      </Wrapper>
      <Wrapper>
        <Image src="/images/global.png" alt="global" />
        <BulletList
          title="VALUE_PROPOSITION3.3.TITLE"
          items={[
            'VALUE_PROPOSITION3.3.ITEM1',
            'VALUE_PROPOSITION3.3.ITEM2',
            'VALUE_PROPOSITION3.3.ITEM3',
          ]}
        />
      </Wrapper>
    </ValueProposition>
    <Button to="/demo">
      <FormattedMessage capitalize id="CTA.LET_US_SHOW_YOU" to="/demo" />
      <Next alt="next" src={withPrefix('/images/next-inverted.svg')} />
    </Button>
  </Slide>
);

FourthSection.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  in: PropTypes.bool,
  intl: PropTypes.intl,
};

export default styled(withIntl(FourthSection))`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;

  ${ValueProposition} {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  ${Title} {
    font-size: 2.8rem;
    font-weight: 500;
    letter-spacing: -0.15rem;
    line-height: 1.18;
    margin: 0 0 3.2rem 0;
  }

  ${Image} {
    margin: 3.2rem 0;
    padding-left: 1rem;
    width: 16rem;
  }

  ${Wrapper} {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    margin-bottom: 1.2rem;
  }

  ${BulletList} {
    ${BulletTitle} {
      font-size: 1.6rem;
      min-height: auto;
      padding-left: 2rem;
    }
    ${Item} {
      font-size: 1.4rem;
    }
  }

  ${Button} {
    align-items: center;
    background-image: linear-gradient(69deg, #005dff, #0095ff);
    border-radius: 0.6rem;
    color: white;
    cursor: pointer;
    display: flex;
    font-size: 1.4rem;
    justify-content: center;
    height: 4.8rem;
    line-height: 1.4rem;
    margin-bottom: 2.4rem;
    padding: 1.6rem;
    text-align: center;
    text-decoration: none;
    width: 100%;

    ${Next} {
      height: 1.2rem;
      margin-left: 1.2rem;
    }
  }

  @media screen and (min-width: 600px) {
    ${ValueProposition} {
      flex-direction: row;
      width: auto;

      ${Wrapper} {
        align-items: center;

        ${BulletList} {
          margin: 0 3.2rem 2.4rem;

          ${BulletTitle} {
            font-size: 2.4rem;
            padding-right: 0;
            text-align: center;
          }
          ${Item} {
            font-size: 1.8rem;
          }
        }
      }
    }

    ${Title} {
      font-size: 3.2rem;
    }

    ${Button} {
      padding: 1.6rem;
      width: 30%;
    }
  }
`;
