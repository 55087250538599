import React from 'react';
import { withPrefix } from 'gatsby';
import PropTypes from 'prop-types';
import styled, { withStyles as css } from '@styled-components';
import { FormattedMessage, withIntl } from '@react-intl';
import { compose } from 'underscore';

import Slide from 'Components/Slide';

const Address = styled('span')``;
const Card = styled('article')``;
const Cards = styled('section')``;
const City = styled('p')``;
const Description = styled('p')``;
const Headquarter = styled('article')``;
const Headquarters = styled('section')``;
const Text = styled('p')``;
const Title = styled('h2')``;
const Anchor = styled('a')`
  display: block;
  position: relative;
  top: -5vh;
  visibility: hidden;

  @media screen and (max-width: 600px) {
    top: -8vh;
  }
`;
const About = ({ className }) => (
  <Slide className={className} in>
    <Anchor id="about" />
    <FormattedMessage capitalize id="ABOUT_US" component={Title}>
      about us
    </FormattedMessage>
    <Headquarters>
      <Headquarter itemScope itemType="http://schema.org/Organization">
        <City>Palo Alto</City>
        <Address itemProp="address">
          2627 Hanover St
          <br />
          Palo Alto, CA 94304
        </Address>
      </Headquarter>
      <Headquarter itemScope itemType="http://schema.org/Organization">
        <City>Madrid</City>
        <Address itemProp="address">
          Calle Eloy Gonzalo 27
          <br />
          28010 Madrid, Spain
        </Address>
      </Headquarter>
    </Headquarters>
  </Slide>
);

About.propTypes = {
  className: PropTypes.string,
};

export default compose(
  css`
    color: #000f19;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    font-size: 1.4rem;
    height: 100%;
    position: relative;
    width: 100%;
    
    &.wrapper {
      background-color: white;
      flex-flow: column;
      min-height: 75vh;
    }
    ${Title} {
      align-self: flex-start;
      font-size: 2.8rem;
      font-weight: 500;
      letter-spacing: -0.1rem;
      line-height: 4rem;
      margin-top: 8%;
    }
    
    ${Text} {
      font-size: 1.6rem;
      line-height: 1.5;
      opacity: 0.6;
    }
    
    ${Headquarters} {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 3.2rem 0;
      width: 100%;
      ${Headquarter} {
        background-image: linear-gradient(170deg, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.5)), url('${withPrefix(
          '/images/madrid.jpg',
        )}');
        background-size: cover;
        border-radius: 6px;
        box-shadow: 0 16px 32px 0 rgba(0, 15, 25, 0.2);
        color: white;
        display: flex;
        flex-basis: 30rem;
        flex-direction: column;
        flex-grow: 1;
        height: 17rem;
        justify-content: flex-end;
        :first-child {
          background-image: linear-gradient(170deg, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.5)), url('${withPrefix(
            '/images/paloalto.jpg',
          )}');
          margin-bottom: 2.4rem;
          margin-right: 0.8rem;
        }
        ${Address} {
          font-size: 1.2rem;
          margin-bottom: 1.6rem;
          opacity: 0.6;
          padding: 0 1.6rem;
        }
        ${City} {
          font-size: 1.6rem;
          line-height: 1.5;
          margin-bottom: 1.6rem;
          padding: 0 1.6rem;
        }
      }
    }
    
    ${Cards} {
      margin-top: 9.6rem;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      justify-content: center;

      > * {
        flex-basis: 23.2rem;
      }
      
      ${Card} {
        display: flex;
        width: 100%;
        max-width: 100%;
        flex-direction: column;
        align-items: center;
        margin-bottom: 3.2rem;
        text-align: center;
        justify-content: center;
        
        ${Description} {
          margin-top: 1.6rem;
          color: #000f19;
          font-size: 1.6rem;
          line-height: 1.5;
          opacity: 0.6;
        }
      }
    }
    
    @media screen and (min-width: 600px) {
      justify-content: space-around;

      ${Headquarters} ${Headquarter} {
        height: 25rem;
      }
    }
    
    @media screen and (min-width: 840px) {
      justify-content: space-between;

      ${Title} {
        font-size: 4rem;
        margin: 6% 0 4.8rem;
      }

      ${Headquarters} {
        ${Headquarter} {
          height: 32rem;

          :first-child {
            margin-right: 3.2rem;
            margin-bottom: 0;
          }

          ${Address} {
            margin-bottom: 3.2rem;
            padding: 0 3.2rem;
          }

          ${City} {
            font-size: 4rem;
            padding: 0 3.2rem;
          }
        }
      }
      ${Cards} {
        
      }
    }

    @media screen and (min-width: 1440px) {
      max-width: 144rem;
      
      &.wrapper {
        width: 100%;
        max-width: 100%;
      }

       ${Headquarters} {
        ${Headquarter} {
          height: 45rem;
        }
      }

      ${Cards} {
        ${Card} {
          min-width: 30%;    
        }
      }
    } 
  `,
  withIntl,
)(About);
