import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'spooner';
import styled, { withStyles as css } from '@styled-components';
import { compose } from 'underscore';
import uuid from 'uuid/v4';

const InputHTML = styled('input')``;
const Label = styled('label')``;

const Radio = ({ className, label, ...props }) => {
  const prevId = useRef(uuid());
  const {
    content,
    id = prevId.current,
    name,
    onBlur = () => {},
    onChange = () => {},
    onFocus = () => {},
    value,
    ...fieldProps
  } = useField(props);

  const handleBlur = () => {
    onBlur();
  };

  const handleChange = event => {
    const { checked } = event.target;

    event.stopPropagation();

    onChange(checked ? content : value);
  };

  const handleFocus = () => {
    onFocus();
  };

  return (
    <div
      className={className}
      data-checked={content === value}
      data-name={name}
      data-value={value}
    >
      <InputHTML
        {...fieldProps}
        checked={content === value}
        id={id}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        type="radio"
      />
      {label && (
        <Label capitalize htmlFor={id}>
          {label}
        </Label>
      )}
    </div>
  );
};

Radio.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
};

export default compose(
  css`
    align-items: center;
    background: white;
    border: 0.1rem solid #ccdfff;
    border-radius: 0.6rem;
    display: inline-flex;
    min-height: 4.8rem;
    padding: 1.6rem 2.4rem 1.6rem 4rem;
    position: relative;
    transition: border-color 250ms ease-out, background 250ms;
    width: 100%;

    &::before {
      background: rgba(255, 255, 255, 0);
      border: 0.1rem solid #ccdfff;
      border-radius: 50%;
      content: '';
      height: 1.6rem;
      left: 1.6rem;
      position: absolute;
      transition: border 250ms, background 250ms;
      width: 1.6rem;
    }

    &[data-checked='true'] {
      background: #e5efff;
      border-color: #005dff;

      &::before {
        background: rgba(255, 255, 255, 1);
        border: 1px solid #005dff;
        border-width: 0.5rem;
        border-color: #005dff;
      }
    }

    ${InputHTML} {
      cursor: pointer;
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      width: 100%;
    }

    ${Label} {
      font-size: 1.4rem;
      font-weight: 500;
      color: #005dff;
      vertical-align: middle;
      word-break: break-all;
    }
  `,
)(Radio);
