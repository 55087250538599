import React, { Component } from 'react';
import { Link as GatsbyLink, withPrefix } from 'gatsby';
import PropTypes from 'prop-types';
import styled from '@styled-components';
import { FormattedMessage } from '@react-intl';

import Player, { Source } from 'Components/Player';
import Slide from 'Components/Slide';

const Link = styled(GatsbyLink)``;
const Actions = styled('div')``;
const Description = styled('p')``;
const Title = styled('h2')``;
const RequestDemo = styled(GatsbyLink)``;
const Button = styled(GatsbyLink)``;

const Scroll = styled('button')``;
const WFTO = styled('img')``;

class Summary extends Component {
  static propTypes = {
    className: PropTypes.string,
    in: PropTypes.bool,
  };

  state = { video: false };

  handleScroll = () => {
    window.scroll({
      behavior: 'smooth',
      top: (window.outerHeight || window.innerHeight) - 90,
    });
  };

  toggle = () => {
    this.setState(prevState => ({ video: !prevState.video }));
  };

  render() {
    const { className, in: visible, ...props } = this.props;
    const { video } = this.state;
    return (
      <Slide
        color="#003566"
        className={className}
        id="summary"
        in={visible}
        {...props}
      >
        <header>
          <FormattedMessage capitalize component={Title} id="SUMMARY.TITLE">
            sell to 3 Billion+ Customers on Social Channels
          </FormattedMessage>
        </header>
        <FormattedMessage
          capitalize
          component={Description}
          id="SUMMARY.DESCRIPTION"
        >
          AI-powered social commerce solution, enabling brands to sell on social
          media, messaging apps and voice-assisted devices
        </FormattedMessage>
        <Actions>
          <FormattedMessage
            capitalize
            component={Link}
            id="WATCH_VIDEO"
            to="/#joinedapp-video"
          />
          <FormattedMessage
            capitalize
            component={RequestDemo}
            id="REQUEST_DEMO"
            to="/demo"
          />
          <Button to="/wfto">
            <WFTO alt="WFTO" src={withPrefix('/images/WFTO.png')} />
            <FormattedMessage capitalize id="WFTO_Members" to="/wfto" />
          </Button>
        </Actions>
        <Scroll onClick={this.handleScroll}>
          <FormattedMessage capitalize id="SCROLL">
            Scroll down
          </FormattedMessage>
        </Scroll>
        {video && (
          <Player autoPlay onDismiss={this.toggle}>
            <Source media="screen and (max-width: 768px)">
              /videos/promo-mobile.mp4
            </Source>
            <Source media="screen and (max-width: 768px)">
              /videos/promo-mobile.webm
            </Source>
            <Source>/videos/promo.mp4</Source>
            <Source>/videos/promo.webm</Source>
          </Player>
        )}
      </Slide>
    );
  }
}

export default styled(Summary)`
  align-items: flex-start;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: none;

  &.wrapper {
    min-height: calc(100vh - 8.2rem);
  }

  ${Title} {
    font-size: 3.4rem;
    font-weight: 500;
    letter-spacing: -0.15rem;
    line-height: 1.18;
    margin: 3.2rem 0;
  }

  ${Description} {
    font-size: 1.6rem;
    line-height: 1.5;
    letter-spacing: 0;
    margin: 0 0 2.4rem;
    opacity: 0.6;
  }

  ${Actions} {
    width: 100%;
  }

  ${Button} {
    align-items: center;
    background-image: linear-gradient(69deg, #005dff, #0095ff);
    border-radius: 0.6rem;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    font-size: 1.4rem;
    height: 4rem;
    line-height: 1.4rem;
    text-align: center;
    text-decoration: none;

    ${WFTO} {
      height: 2.8rem;
      margin-right: 1.2rem;
    }
  }

  ${Link}, ${RequestDemo}, ${Button} {
    border-radius: 0.6rem;
    font-size: 1.4rem;
    line-height: 1.4rem;
    padding: 1.6rem ;
    text-align: center;
    text-decoration: none;
  }

  ${Link} {
    background: white;
    border: solid 0.1rem #0084ff;
    cursor: pointer;
    color: #0084ff;
    display: block;
    margin-bottom: 2.4rem;
    padding: 1.6rem;
    width: 100%;
  }

  ${RequestDemo} {
    background-image: linear-gradient(69deg, #005dff, #0095ff);
    display: block;
    padding: 1.6rem;
    margin-bottom: 2.4rem;
  }

  ${Scroll} {
    background: url('${withPrefix(
      '/images/icons/scroll.svg',
    )}') center no-repeat;
    border: 0;
    bottom: 1%;
    display: none;
    height: 4rem;
    left: 8%;
    position: absolute;
    text-indent: -9999px;
    width: 84%;
    
    &:focus {
      outline: 0;
    }
  }
}

  @media screen and (min-width: 600px) {
    justify-content: center;
    margin-top: 25%;
    padding: 0 2.4rem;
    
    &.wrapper {
      background: url('/images/backgrounds/summary.svg') center 25% / 50% no-repeat;
    }
    
    ${Title} {
      font-size: 5.6rem;
      line-height: 6.4rem;
    }
    
    ${Description} {
      font-size: 1.8rem;
      line-height: 2.4rem;
      letter-spacing: 0;
    }

    ${Link}, ${RequestDemo} {
      font-size: 1.4rem;
      max-width: 15.5rem;
      padding: 1.2rem 2.4rem;
      text-align: center;
      text-decoration: none;
      display: inline-block;
    }

    ${Button} {
      font-size: 1.4rem;
      max-width: 32.6rem;
      padding: 1rem 2.4rem;
      text-align: center;
      text-decoration: none;
    }

    ${Link} {
      margin-right: 1.6rem;
    }
  }
  
  @media screen and (min-width: 840px) {
    margin-top: 0;
    padding: 0;
    width: 50%;
    &.wrapper {
      background-position: 86% center;
      background-size: 45%;
      justify-content: flex-start;
    }

    ${Scroll} {
      display: block;
    }
  }

  @media screen and (min-width: 1280px) {
    padding-left: 11.4rem;
    width: 50%;
    &.wrapper {
      background-position-y: 65%;   
      justify-content: flex-start;
    }
  }
  
  @media screen and (min-width: 1441px) {
    &.wrapper {
      background-position: 100% center;
      background-size: 60%;
      max-width: 121.6rem;
      padding: 0;
      ${Description} {
        width: 48.8rem;
      }
    }
  }
`;
