import React from 'react';
import PropTypes from '@prop-types';
import { useValue } from 'spooner';
import styled from '@styled-components';

const Action = ({ className, field, ...props }) => {
  const { value } = useValue(field);
  const completed = Boolean(value);

  return (
    <button
      className={className}
      disabled={!completed}
      type="button"
      {...props}
    />
  );
};

Action.propTypes = {
  className: PropTypes.string,
  field: PropTypes.string,
};

export default styled(Action)``;
