import React from 'react';
import PropTypes from '@prop-types';
import { FormattedMessage } from '@react-intl';
import { Link as GatsbyLink } from 'gatsby';
import styled from '@styled-components';

const Button = styled(GatsbyLink)``;
const Title = styled('h3')``;
const Description = styled('p')``;

const Cover = ({ className, onStart = () => {} }) => (
  <section className={className}>
    <FormattedMessage capitalize component={Title} id="D2C.TITLE">
      How strong is your D2C strategy?
    </FormattedMessage>
    <FormattedMessage capitalize component={Description} id="D2C.DESCRIPTION">
      take a quiz to learn about the strengh of your social media
    </FormattedMessage>
    <FormattedMessage
      capitalize
      component={Button}
      id="D2C.TAKE_THE_QUIZ"
      to="#quiz"
      onClick={onStart}
    />
  </section>
);

Cover.propTypes = {
  className: PropTypes.string,
  onStart: PropTypes.func,
};

export default styled(Cover)`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;

  ${Title} {
    font-size: 2.8rem;
    font-weight: 500;
    letter-spacing: -0.15rem;
    line-height: 1.18;
    margin: 0 0 3.2rem 0;
  }

  ${Description} {
    opacity: 0.6;
    font-size: 1.6rem;
    margin-top: 4rem;
    text-align: center;
  }

  ${Button} {
    background-image: linear-gradient(69deg, #005dff, #0095ff);
    border-radius: 0.6rem;
    color: white;
    cursor: pointer;
    display: block;
    font-size: 1.4rem;
    height: 4.8rem;
    line-height: 1.4rem;
    margin-bottom: 2.4rem;
    padding: 1.6rem;
    text-align: center;
    text-decoration: none;
    margin-top: 6.4rem;
  }

  @media screen and (min-width: 600px) {
    ${Title} {
      font-size: 3.2rem;
    }
    ${Description} {
      font-size: 2.4rem;
    }
  }
`;
