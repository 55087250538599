import React, { createRef, PureComponent } from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';
import { withIntl } from '@react-intl';

import Slide from 'Components/Slide';
import Card from 'Components/Card';
import Carousel, { Item } from 'Components/Carousel';

class News extends PureComponent {
  container = createRef();

  static propTypes = {
    className: PropTypes.string,
    in: PropTypes.bool,
    intl: PropTypes.intl,
  };

  state = { items: 1 };

  componentDidMount() {
    const { width } = this.container.current.getBoundingClientRect();

    if (width > 600) {
      this.setState({ items: width < 840 ? 2 : 3 });
    }
  }

  render() {
    const { className, in: visible, intl } = this.props;
    const { items } = this.state;

    return (
      <Slide
        color="#f7f9fc"
        className={className}
        id="inthenews"
        in={visible}
        title={intl.formatMessage({ capitalize: true, id: 'IN_THE_NEWS' })}
      >
        <div ref={this.container} style={{ maxWidth: '100%' }}>
          <Carousel dots infinite items={items}>
            <Item itemScope itemType="http://schema.org/NewsArticle">
              <Card
                description="Ingenico Group, the global leader in seamless payment, today announced its investment in Joinedapp, a start-up located in Palo Alto, California whose enterprise e-commerce..."
                image="/images/man-using-phone.jpg"
                link="https://www.ingenico.com/press-and-publications/press-releases/finance/2017/06/ingenico-group-invests-in-joinedapp.html"
                title="Ingenico Group invests in Joinedapp, a California-based start-up, to shape the future of conversational commerce"
              />
            </Item>
            <Item itemScope itemType="http://schema.org/NewsArticle">
              <Card
                description="Ingenico Group is about to take conversational commerce mainstream with the launch of a payment-enabled messaging bot that will transform the way we buy online..."
                image="/images/mobile2.jpg"
                link="https://blog.ingenico.com/posts/2017/09/chatbots-and-the-conversational-commerce-revolution.html"
                title="Chatbots and the Conversational Commerce Revolution"
              />
            </Item>
            <Item itemScope itemType="http://schema.org/NewsArticle">
              <Card
                description="Ingenico ePayments, the division of Ingenico Group dedicated to online and mobile commerce, wins the ID d'Or trophy for the 4th edition of the Digital Innovation Grand Prix for its integrated payment solution."
                image="/images/prix-id-dor.jpg"
                link="https://ingenico.fr/media/communiques-de-presse/group/communiques-de-presse/all/2017/07/grand-prix-innovation.html"
                title="Ingenico ePayments wins the Golden ID of the Digital Innovation Grand Prix in the Commerce category (French)"
              />
            </Item>
            <Item itemScope itemType="http://schema.org/NewsArticle">
              <Card
                description="Payment giant Ingenico has taken a stake in Californian start-up Joinedapp. Together, the two companies intend to develop chatbots with built-in payment functionality..."
                image="/images/ingenico-mwc-2017.jpg"
                link="https://www.usine-digitale.fr/article/e-commerce-ingenico-investit-dans-les-chatbots-de-la-start-up-joinedapp.N559198"
                title="[E-commerce] Ingenico invests in chatbots through start-up Joinedapp (French)"
              />
            </Item>
            <Item itemScope itemType="http://schema.org/NewsArticle">
              <Card
                description="Chatbots, computer algorithms that can be used by retailers to chat to customers via text or voice, are surging ahead in popularity as retailers recognize their potential in the e-commerce marketplace..."
                image="/images/mobile3.jpg"
                link="https://blog.ingenico.com/posts/2017/03/chatbots-to-dominate-e-commerces-future.html?utm_source=twitter&utm_campaign=mwc"
                title="Chatbots to dominate e-commerce’s future"
              />
            </Item>
            <Item itemScope itemType="http://schema.org/NewsArticle">
              <Card
                description="Chatbot. It’s a word that you’ve probably been hearing a lot recently. As one of the top new trends in retail, you’re going to keep on hearing it..."
                image="/images/mobile.jpg"
                link="https://www.insider-trends.com/what-chatbots-mean-for-retail/"
                title="Chatbots in retail: the ultimate guide"
              />
            </Item>
          </Carousel>
        </div>
      </Slide>
    );
  }
}

export default styled(withIntl(News))`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 144rem;

  &.wrapper {
    align-content: center;
    background-color: #f7f9fc;
    padding: 3.2rem 0 0;
    max-width: 100%;
  }

  ${Item} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0 5%;
  }

  ${Card} {
    border: 4px solid transparent;
    box-shadow: 0 64px 64px 0 rgba(0, 15, 25, 0.05);

    height: 43.1rem;
    margin-bottom: 0;
    padding: 2.8rem;
    transition: border-color 0.125s ease-out;
    width: 38.4rem;

    &:focus {
      border-color: rgba(0, 132, 255, 0.2);
      outline: 0;
    }

    &:hover {
      border-color: rgba(0, 132, 255, 0.4);
    }
  }

  @media screen and (min-width: 600px) {
    ${Card} {
      width: 38.4rem;
      margin: 0 1rem;
    }
  }

  @media screen and (min-width: 1280px) {
    ${Card} {
      width: 38.4rem;
    }
  }
`;
