import React, { useRef } from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';
import uuid from 'uuid/v4';

const Chunk = styled('span')``;

const ProgressBar = ({ className, numberOfSteps, currentStep }) => {
  const { current: steps } = useRef(
    Array(numberOfSteps)
      .fill(null)
      .map(() => uuid()),
  );

  return (
    <section className={className}>
      {steps.map((uid, index) => (
        <Chunk data-behind={index <= currentStep} key={uid} />
      ))}
    </section>
  );
};

ProgressBar.propTypes = {
  className: PropTypes.string,
  currentStep: PropTypes.number,
  numberOfSteps: PropTypes.number,
};

export default styled(ProgressBar)`
  display: grid;
  grid-template-columns: ${props => `repeat(${props.numberOfSteps || 4}, 1fr)`};
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 0.8rem;
  overflow: hidden;
  border-radius: 0 0 0.8rem 0.8rem;
  align-items: flex-end;

  ${Chunk} {
    background: #f2f3f4;
    border-left: 1px solid #d2d3d4;
    height: 0.4rem;
    transition: background 350ms, border 500ms;
    width: 100%;

    &[data-behind='true'] {
      background: #005dff;
      border-left: 1px solid #0331c0;
    }
  }
`;
