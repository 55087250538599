import React from 'react';
import PropTypes from '@prop-types';
import { FormattedMessage, withIntl } from '@react-intl';
import { Link as GatsbyLink } from 'gatsby';
import styled from '@styled-components';

import Value from 'Components/Value';
import OrderedList from 'Components/OrderedList';

const Button = styled(GatsbyLink)``;

const SecondSection = ({ className, in: visible, intl, ...props }) => (
  <Value
    className={className}
    color="#f7f9fc"
    heading={intl.formatMessage({
      capitalize: true,
      id: 'SOCIAL_COMMERCE',
    })}
    id="first"
    image="/images/backgrounds/flow.svg"
    in={visible}
    title={intl.formatMessage({
      capitalize: true,
      id: 'VALUE_PROPOSITION1.TITLE',
    })}
    {...props}
  >
    <OrderedList
      items={[
        'VALUE_PROPOSITION1.DESCRIPTION1',
        'VALUE_PROPOSITION1.DESCRIPTION2',
        'VALUE_PROPOSITION1.DESCRIPTION3',
      ]}
    />
    <FormattedMessage
      capitalize
      component={Button}
      id="CTA.OPTIMIZE_YOUR_BRAND"
      to="/demo"
    />
  </Value>
);

SecondSection.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  in: PropTypes.bool,
  intl: PropTypes.intl,
  style: PropTypes.objectOf(PropTypes.string),
};

export default styled(withIntl(SecondSection))`
  background-color: transparent;
  ${Button} {
    background-image: linear-gradient(69deg, #005dff, #0095ff);
    border-radius: 0.6rem;
    color: white;
    cursor: pointer;
    display: block;
    font-size: 1.4rem;
    height: 4.8rem;
    line-height: 1.4rem;
    margin-top: 6.4rem;
    padding: 1.6rem;
    text-align: center;
    text-decoration: none;
    width: 100%;
  }

  ${OrderedList} {
    margin-top: 6.4rem;
  }

  @media screen and (min-width: 600px) {
    ${Button} {
      width: 28rem;
    }
  }
`;
