import React from 'react';
import PropTypes from '@prop-types';
import { FormattedMessage } from '@react-intl';
import styled from '@styled-components';

import List from 'Components/List';

const FakeTab = ({ className, id }) => {
  return (
    <li className={className}>
      <FormattedMessage id={id} />
    </li>
  );
};

FakeTab.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
};

const FakeTabs = ({ className, index, items }) => {
  return (
    <List
      className={className}
      items={items}
      noWrapper
      options={{ index }}
      template={FakeTab}
    />
  );
};

FakeTabs.propTypes = {
  className: PropTypes.string,
  index: PropTypes.number,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
};

export default styled(FakeTabs)`
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  overflow: hidden;
  padding: 1rem 0;
  position: relative;

  li {
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 2rem;
    list-style: none;
    min-width: 100%;
    opacity: 0.65;
    position: relative;
    transition: transform 250ms ease-out;
    transform: translateX(-${props => props.index * 100}%);
    white-space: nowrap;

    &::before {
      background: #fff;
      content: '';
      height: 0.2rem;
      opacity: 0.2;
      position: absolute;
      top: 3rem;
      width: 100%;
      z-index: 1;
    }
  }
`;
