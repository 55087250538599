import React, { Component, createRef } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styled, { withStyles as css } from '@styled-components';
import _ from 'underscore';

import Item from './Item';
import withControlledProp from '../with/controlledProp';

const Back = styled('button')``;
const Dots = styled('div')``;
const Next = styled('button')``;
const Wrapper = styled('ul')``;

export const Dot = styled('div')`
  &::after {
    background: ${props =>
      // eslint-disable-next-line no-nested-ternary
      props.selected
        ? 'none'
        : props.inverted
        ? 'rgba(255, 255, 255, 1)'
        : 'rgba(0, 15, 25, 1)'};
    border: ${props =>
      // eslint-disable-next-line no-nested-ternary
      props.selected
        ? props.inverted
          ? 'solid 0.1rem rgba(255, 255, 255, 0.4)'
          : 'solid 0.1rem rgba(0, 132, 255, 0.4)'
        : props.inverted
        ? 'none'
        : 'solid 0.1rem rgba(0, 0, 0, 1)'};
    opacity: ${props => (props.selected ? 1 : 0.1)};
  }
`;

export class Carousel extends Component {
  element = createRef();

  last = null;

  length = this.props.children
    ? Math.ceil(this.props.children.length / this.props.items)
    : 0;

  position = null;

  size = 100 / this.props.items;

  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    className: PropTypes.string,
    dots: PropTypes.bool,
    handleIndex: PropTypes.func,
    hint: PropTypes.bool,
    index: PropTypes.number,
    infinite: PropTypes.bool,
    inverted: PropTypes.bool,
    items: PropTypes.number,
    onPageChange: PropTypes.func,
    range: PropTypes.number,
  };

  static defaultProps = {
    dots: false,
    handleIndex() {},
    hint: true,
    index: 0,
    infinite: false,
    items: 1,
    range: 1,
  };

  state = {
    offset: (this.props.index + this.props.infinite * this.length) * this.size,
  };

  componentDidMount() {
    window.addEventListener('mousemove', this.handleMove, { passive: true });
    window.addEventListener('mouseup', this.handleUp, { passive: true });
  }

  componentDidUpdate(prevProps) {
    const { children, index, infinite, items } = this.props;

    if (index !== prevProps.index) {
      this.setState(
        { offset: (index + infinite * this.length) * this.size },
        () => {
          this.updating = false;
        },
      );
    } else if (items !== prevProps.items) {
      this.length = Math.ceil(children.length / items);
      this.setState({
        offset: (index + infinite * this.length) * this.size,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.handleMove);
    window.removeEventListener('mouseup', this.handleUp);
  }

  handleDown = event => {
    event.preventDefault();
    this.start('pageX' in event ? event.pageX : event.touches[0].pageX);
  };

  handleMove = event => {
    this.move('pageX' in event ? event.pageX : event.touches[0].pageX);
  };

  handleUp = () => {
    this.end(this.last);
  };

  next = () => {
    const { handleIndex, index, onPageChange } = this.props;

    handleIndex(index + 1, onPageChange);
  };

  back = () => {
    const { handleIndex, index, onPageChange } = this.props;

    handleIndex(index - 1 || 0, onPageChange);
  };

  update = () => {
    const { handleIndex, index: before, onPageChange } = this.props;
    const { offset } = this.state;

    const after = Math.round(offset / this.size) % this.length;

    if (after !== before) {
      this.updating = true;
      handleIndex(after, onPageChange);
    }
  };

  start(x) {
    const { items } = this.props;

    if (this.length <= items) {
      return null;
    }

    // eslint-disable-next-line react/no-unused-state
    return this.setState({ isMoving: true }, () => {
      this.last = x;
      this.position = x;
    });
  }

  move(x) {
    if (!this.position) {
      return null;
    }

    return this.setState(
      prevState => {
        const distance = this.last - x;
        const { offsetWidth: width } = this.element.current;

        return { offset: prevState.offset + (distance / width) * 100 };
      },
      () => {
        this.last = x;
      },
    );
  }

  end(x) {
    if (!this.position) {
      return null;
    }

    const { index: before, infinite, range } = this.props;
    const distance = x - this.position;
    const percentage = Math.abs(distance / this.element.current.offsetWidth);
    let after = before;

    if (Math.abs(distance / this.element.current.offsetWidth) > 0.2) {
      after += Math.round(
        (distance < 0 ? range : -range) * (range > 1 ? percentage : 1),
      );
    }

    if (!infinite) {
      after = Math.min(Math.max(0, after), this.length - Math.max(range, 1));
    }

    this.position = null;
    return this.setState({
      offset: (after + infinite * this.length) * this.size,
    });
  }

  render() {
    const { length } = this;
    const {
      children,
      className,
      dots,
      hint,
      index,
      infinite,
      inverted,
      items,
    } = this.props;
    const { offset } = this.state;
    const isMoving = this.position || this.updating;

    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        aria-roledescription="carousel"
        className={className}
        data-next={hint && (index + items <= length - 1 || infinite)}
        data-previous={hint && (index > 0 || infinite)}
        onMouseDown={this.handleDown}
        onTouchEnd={this.handleUp}
        onTouchMove={this.handleMove}
        onTouchStart={this.handleDown}
        onTransitionEnd={this.update}
        ref={this.element}
      >
        {hint && <Back onClick={this.back}>Previous</Back>}
        <Wrapper
          className={cx({ animated: !isMoving })}
          style={{ transform: `translate3d(${-offset}%, 0, 0)` }}
        >
          {infinite && children}
          {children}
          {infinite && children}
        </Wrapper>
        {hint && <Next onClick={this.next}>Next</Next>}
        {dots && (
          <Dots>
            {Array.from(Array(this.length).keys()).map(i => (
              <Dot inverted={inverted} key={i} selected={index === i} />
            ))}
          </Dots>
        )}
      </div>
    );
  }
}

export default _.compose(
  css`
    margin: 0;
    overflow: hidden;
    position: relative;

    &[data-next],
    &[data-previous] {
      &::after,
      &::before {
        content: '';
        display: block;
        height: calc(100% - 2rem);
        opacity: 0;
        position: absolute;
        top: 0;
        transition: opacity 0.15s ease-out;
        width: 3rem;
        z-index: 10;
      }
    }

    &[data-next='true']::after {
      opacity: 1;
    }

    &[data-previous='true']::before {
      opacity: 1;
    }

    ${Wrapper} {
      display: flex;
      height: calc(100% - 2rem);
      list-style: none;
      margin: 0 2.4rem;
      padding: 0;

      &.animated {
        transition: transform 0.2s ease-out;
      }
    }
    ${Back}, ${Next} {
      display: none;
    }
    ${Item} {
      flex-grow: 1;
      max-width: ${props => 100 / (props.items || 1)}%;
      min-width: ${props => 100 / (props.items || 1)}%;
    }

    ${Dots} {
      align-items: center;
      display: flex;
      height: 10rem;
      justify-content: center;
      position: relative;

      ${Dot} {
        align-items: center;
        display: inline-flex;
        height: 0.8rem;
        justify-content: center;
        margin-right: 0.8rem;
        transition: opacity 0.2s ease-out;
        width: 0.8rem;

        &::after {
          border-radius: 50%;
          content: '';
          height: 0.8rem;
          width: 0.8rem;
        }

        &:last-of-type {
          margin: 0;
        }
      }
    }

    @media screen and (min-width: 600px) {
      ${Back}, ${Next} {
        background-color: white !important;
        border-radius: 50%;
        border: none;
        box-shadow: 0 64px 64px 0 rgba(0, 15, 25, 0.03);
        cursor: pointer;
        display: block;
        height: 4.8rem;
        outline-color: transparent;
        position: absolute;
        text-indent: -9999px;
        top: 40%;
        width: 4.8rem;
        z-index: 1;
      }
      ${Back} {
        background: url('/images/back.svg') no-repeat center;
        float: left;
        height: 4.8rem;
        left: 0;
        margin-left: 4rem;
      }
      ${Next} {
        background: url('/images/next.svg') no-repeat center;
        float: right;
        height: 4.8rem;
        margin-right: 4rem;
        right: 0;
      }
    }
  `,
  withControlledProp('index'),
)(Carousel);
